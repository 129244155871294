<template>
  <v-text-field
    ref="autocomplete"
    v-model="cityName"
    @change="setLocation"
    placeholder="City"
    outlined
    :error-messages="errorMessages"
  ></v-text-field>
</template>

<script>
import Config from "../../services/config";
export default {
  name: "CityTextField",
  props: ["value", "countryCode", "errorMessages"],
  data() {
    return {
      cityName: null,
    };
  },
  mounted() {
    this.checkAndAttachAutocompleteScript(() => {
      if (this.$refs.autocomplete) {
        let element = this.$refs.autocomplete.$el;
        element = element.querySelector("input");
        this.autocomplete = new window.google.maps.places.Autocomplete(
          element,
          {
            types: ["(cities)"],
          }
        );
      }
    });
  },
  methods:{
    checkAndAttachAutocompleteScript(callback) {
      let scriptId = "autocomplete-api-script";
      let scriptAlreadyAttached = !!document.getElementById(scriptId);
      if (scriptAlreadyAttached) {
        if (window.google)
          // Script attached but may not finished loading; so check for 'google' object.
          callback();
        window.autocompleteApiInitialized = callback;
        const APIKEY = Config.gMapAPIKey;
        let script = document.createElement("script");
        script.id = scriptId;
        script.src = `https://maps.googleapis.com/maps/api/js?key=${APIKEY}&libraries=places&language=en&callback=autocompleteApiInitialized&v=weekly`;
        document.body.appendChild(script);
      } else {
        window.autocompleteApiInitialized = callback;
        const APIKEY = Config.gMapAPIKey;
        let script = document.createElement("script");
        script.id = scriptId;
        script.src = `https://maps.googleapis.com/maps/api/js?key=${APIKEY}&libraries=places&language=en&callback=autocompleteApiInitialized&v=weekly`;
        document.body.appendChild(script);
      }
      return scriptAlreadyAttached;
    },
    setLocation() {
      this.autocomplete.addListener("place_changed", () => {
        let place = this.autocomplete.getPlace();
        this.cityName = place.vicinity;
        this.value = this.cityName;
        this.$emit("input", place.vicinity);
      });
    },
  }
}
</script>

<style scoped>

</style>
